import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {

    render() {
        const { children, triggerLogin, ...props } = this.props;
        const style = {
            background: '#eee',
            border: '1px solid black',
            borderRadius: '3px',
            display: 'inline-block',
            margin: '5px',
            padding: '10px 20px'
        }
        return (
            <div onClick={triggerLogin} style={style} {...props}>
                {children}
            </div>
        );
    }
}

export default SocialLogin(SocialButton);

