import { func } from "prop-types";
import React, { useState, useCallback, Fragment } from "react";
import SocialButton from "../../../shared/components/socialLoginButton"
import { Typography, Box, Grid, Link, Button, List, ListItem, Divider } from "@mui/material";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoginIcon from '@mui/icons-material/Login';
import { AuthContext } from "../../../App";
import server from "../../../backend/server";

import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
    LoginSocialTiktok,
} from 'reactjs-social-login'

import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
    AppleLoginButton,
} from 'react-social-login-buttons'
import data from "../../../logged_in/dummy_data/persons";


const styles = (theme) => ({
    wrapper: {
        backgroundColor: theme.palette.common.white,
        overflowX: "hidden",
    },
});

//const sdkLink = "https://drive.google.com/file/d/1YPzTHIXdr1HkIItmRunrlVg2QjcOOFRq/view?usp=share_link"
//const sdkLink = "https://drive.google.com/file/d/12fz9hJXI5kTQtypDuodY1a_58-MKH_1N/view?usp=share_link"

// 1.1.0 const sdkLink = "https://drive.google.com/file/d/1Lg72C9KXPOWtZwmdid4UjK6kGfWMqZkD/view?usp=share_link"
// 1.3.1 updated jan 13 2024
const sdkLink = "https://drive.google.com/file/d/1Gxc17MQBmUD1cXz8VlH9wknl4W_BL_b-/view?usp=share_link"

const loginByGoogle = true

function DownloadLanding(props) {
    const { classes, theme, openRegisterDialog, openLoginDialog/*, setRegistered*/ } = props;
    const { state: authState, dispatch } = React.useContext(AuthContext);

    //const [localLogin, setLocalLogin] = useState(false)
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState([]);

    const onLoginStart = useCallback(() => {
        //alert('login start')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        //setLocalLogin(false)
        alert('logout success')
    }, [])

    async function handleSocialLogin(provider, userdata) {
        //setRegistered(true)
        setProvider(provider)
        //setLocalLogin(true)
        let ret = await server.getUserFromGoogle(userdata.access_token)
        if (ret.success) {
            var userProfile = {}
            userProfile.provider = "google"
            userProfile.token = userdata.access_token
            userProfile.user = ret.user
            setProfile(ret.user)
            dispatch({ type: 'LOGIN', payload: userProfile })
            console.log("logged in")
            server.logScoialAccount(ret.user.email, userProfile)
        }
    }

    function handleSocialLoginFailure() {
        console.log("logged out")
        //setRegistered(false)
        //setLocalLogin(false)
    }
    function onLogout() {
        dispatch({ type: 'LOGOUT'})
    }

    function debugPrint() {
        return (
            <div className='content'>
                <div className='data'>
                    {Object.entries(profile).map(([key, value]: any) => (
                        <div className='field' key={key}>
                            <div className='label'>{key}: </div>
                            <div className='value'>{JSON.stringify(value)}</div>
                        </div>
                    ))}
                </div>
                <button className='btnLogout' onClick={onLogout}>
                    Logout
                </button>
            </div>

        );
    }

    function showLogin() {
        return (
            <Fragment>
                <Typography variant="h6" component="pre" align="center" style={{ fontWeight: 500, color: '#4343d9' }}>
                    Please login to download the SDK. 
                    <br/>
                    If you don't have an account, please sign up. The process is quick and easy.
                </Typography>
                <Divider/>
                <List>
                    {
                        loginByGoogle ?
                        <ListItem>
                            <LoginSocialGoogle
                                isOnlyGetToken
                                client_id={process.env.REACT_APP_GG_APP_ID || ''}
                                onLoginStart={onLoginStart}
                                onResolve={({ provider, data }) => {
                                    //setProvider(provider)
                                    handleSocialLogin(provider, data)
                                    /*setProfile(data)
                                    setLocalLogin(true)
                                    setRegistered(true)*/

                                    console.log(data)
                                }}
                                scope="openid profile email"
                                discoveryDocs="claims_supported"
                                access_type="offline"
                                onReject={(err) => {
                                    console.log(err)
                                }}
                                fetch_basic_profile={true}
                            >
                                <GoogleLoginButton />
                            </LoginSocialGoogle>
                            </ListItem>
                            :<></>
                    }
                    <ListItem>
                    <Button
                        sx={{ color: 'white', width: '230px' }}
                        //color= "secondary"
                        size="large"
                        onClick={openLoginDialog}
                        classes={{ text: classes.menuButtonText }}
                        variant="contained"
                        startIcon={<LoginIcon />}
                    >
                        Login
                    </Button>
                </ListItem>

                    <ListItem>
                <Button
                            sx={{ color: 'white', width: '230px' }}
                    //color= "secondary"
                    size="large"
                    onClick={openRegisterDialog}
                    classes={{ text: classes.menuButtonText }}
                            variant="contained"
                            startIcon={<AppRegistrationIcon />}
                >
                    Sign Up
                        </Button>
                        </ListItem>
                </List>
            </Fragment>
        );
    }


    return (
        <div className={classNames("lg-p-top", classes.wrapper)}>
            <div className={classNames("container-fluid", classes.container)}>
                <Box alignItems="center" justifyContent="center" className="column">            
                    <Typography variant="h4" align="center" className="lg-mg-bottom" style={{ fontWeight: 700, color: '#4343d9' }}>Athena SDK Free Download</Typography>
                    <Box flexDirection="column" alignItems="center" justifyContent="center" className={classNames("row", "lg-mg-bottom")}>
                        {
                            !authState.isAuthenticated?              
                                showLogin()
                                :
                                <Fragment>
                                <Box alignItems="center" justifyContent="center" className="row">
                                    <img src="images/download.png" />
                                    <Typography variant="h5" align="center"><Link href={sdkLink}>Download Link</Link></Typography>
                                </Box>
                                </Fragment>
                            
                        }
                    </Box>
                </Box>
                </div>
        </div>
    );
}

DownloadLanding.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    openRegisterDialog: PropTypes.func.isRequired,
    openLoginDialog: PropTypes.func.isRequired
    //setRegistered: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DownloadLanding);

{/*  <SocialButton
                provider="google"
                appId="224324856183-4r05lt60ab8ps7nhlft62m1l9i8qsl6k.apps.googleusercontent.com"
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
            >
                Login with Google
                            </SocialButton>
                          <SocialButton
                        provider="facebook"
                        appId="YOUR_APP_ID"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                    >
                        Login with Facebook
                        </SocialButton>
                        <SocialButton
                            provider="github"
                            appId="YOUR_APP_ID"
                            onLoginSuccess={handleSocialLogin}
                            onLoginFailure={handleSocialLoginFailure}
                        >
                            Login with gitHub
                        </SocialButton>
    */}