
//import fetch from 'node-fetch';
export var token;
//var serverUrl = 'https://athena-backend.onrender.com';
var serverUrl = 'https://api.athenasaas.io';
if (process.env.NODE_ENV === 'development') {
    serverUrl = 'http://localhost:4000'
}
console.log(serverUrl)

/**
 * 
 * @param {*} params {email: email, password: password}
 * @returns {success, message}
 */
async function registerAccount(params) {
    try {
        const response = await fetch(`${serverUrl}/register`, {
            method: 'post',
            body: JSON.stringify(params),
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        console.log(data);
        //token = data.token
        return data
    } catch (error) {
        console.log(error)
        return {success:false, message: error.message + " Please also check your network connection"}
    }
}

async function logScoialAccount(email,params) {
    try {
        const response = await fetch(`${serverUrl}/sociallogin`, {
            method: 'post',
            body: JSON.stringify({ email: email, profile: params }),
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        console.log(data);
        //token = data.token
        return data
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }
}

/**
 * 
 * @param {*} params : {email: email, password: password}
 * @returns true/false
 */

async function login(params) {
    try {
        const response = await fetch(`${serverUrl}/login`, {
            method: 'post',
            body: JSON.stringify(params),
            headers: { 'Content-Type': 'application/json' }
        });
        var data = {}
        try {
            data = await response.json();
            console.log(`token: ${data.token}`)
            data.token = data.token.split(' ')[1]
            console.log(`token: ${data.token}`)
        } catch (error) {
            return { success: false, message: error }
        }
        console.log(data);
        if (data.success) {
            token = data.token.split(' ')[1]
        } else {
            token = null
        }
        return data
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }
}

async function getUserFromGoogle(accessToken) {
    try {
        const response = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        console.log(data);
        return { success: true, user: data }
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }
}

async function supportRequest(email, params) {
    try {
        const response = await fetch(`${serverUrl}/support-request`, {
            method: 'post',
            body: JSON.stringify({ email: email, request: params }),
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        console.log(data);
        //token = data.token
        return data
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }
}

async function submitSupportReply(email, params, token) {
    console.log(`token:${token}`)
    try {
        const response = await fetch(`${serverUrl}/admin/v1/support-reply`, {
            method: 'post',
            body: JSON.stringify({ email: email, reply: params }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        const data = await response.json();
        console.log(data);
        return data
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }

}

async function replyList(email, requestid, token) {
    console.log(`token:${token}`)
    try {
        const response = await fetch(`${serverUrl}/admin/v1/support-reply-list`, {
            method: 'post',
            body: JSON.stringify({ email: email, requestid: requestid }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        const data = await response.json();
        console.log(data);
        return data
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }
}

async function resendEmailVerifyLink(email) {
    try {
        const response = await fetch(`${serverUrl}/emailverifylink`, {
            method: 'post',
            body: JSON.stringify({ email: email}),
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        console.log(data);
        //token = data.token
        return data
    } catch (error) {
        console.log(error)
        return { success: false, message: error.message + " Please also check your network connection" }
    }

}

export default {
    registerAccount,
    login,
    token,
    getUserFromGoogle,
    logScoialAccount,
    supportRequest,
    resendEmailVerifyLink,
    submitSupportReply,
    replyList
}