import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import DownloadLanding from "./logged_out/components/register_login/DownloadLanding";
import {useCookies} from 'react-cookie'

export const AuthContext = React.createContext();

//import './font_inter.css'

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  provider:null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      localStorage.setItem("provider", JSON.stringify(action.payload.provider));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        provider: action.payload.provider
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};


function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['userInfo'])
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    if (cookies['loginInfo'] !== undefined) {
      let loginInfo = cookies['loginInfo']
      if (loginInfo.saveuser) {
        let user = {
          provider: loginInfo.provider, token: loginInfo.token, user:
            JSON.parse(localStorage.getItem('user'))
        }
        dispatch({ type: 'LOGIN', payload: user })
        }
    }
  },[])

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthContext.Provider value={{
            state,
            dispatch
          }}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <Switch>
              <Route path="/c">
                <LoggedInComponent />
              </Route>

              {/*<Route path="/download">
                <DownloadLanding />
  </Route>*/}

              <Route>
                <LoggedOutComponent />
              </Route>
            </Switch>
            </Suspense>
          </AuthContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
